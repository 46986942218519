import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled.div`
	margin: 6rem 0rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: ${props => props.direction === 'left' ? 'row-reverse' : 'row'};
`

const TextContent = styled.div`
	flex: 1;
	margin-right: ${props => props.direction === 'left' ? '0rem' : '5rem'};
	margin-left: ${props => props.direction === 'left' ? '5rem' : '0rem'};
	@media (max-width: 768px) {
		margin-right: 0rem;
		margin-left: 0rem;
		width: 100%;
  }

	a {
		font-weight: 600;
	}
`

const HeroImgContainer = styled.div`
	@media (max-width: ${ props => props.theme.maxWidth}){
		margin-right: ${props => props.direction === 'left' ? '0rem' : '-3rem'};
		margin-left: ${props => props.direction === 'left' ? '-3rem' : '0rem'};
  }
	@media (max-width: 768px) {
		margin-top: 3rem;
		width: 100%;
  }
`

const HeroImg = styled.img`
	height: ${props => `${props.dimensions.height}px`};
	width: ${props => `${props.dimensions.width}px`};
	@media (max-width: 768px) {
		width: 100%;
		height: auto;
  }
`

const HeroImgLa = styled(HeroImg)`
  display: none;
  @media (min-width: ${props => props.theme.breakpoints.m}) {
    display: block;
  }
`

const HeroImgT = styled(HeroImg)`
  display: none;
  @media (min-width: 769px) and (max-width: 899px) {
    display: block;
  }
`

const HeroImgM = styled(HeroImg)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const ProjectDetailsPhoto = ({ input, direction }) => {
	const { primary: { project_info_block_image: { url, dimensions, Mobile, Tablet }, project_info_block: { html } } } = input 
	return (
		<Container direction={direction}>
			<TextContent direction={direction} dangerouslySetInnerHTML={{ __html: html }} />
			<HeroImgContainer direction={direction}>
				<HeroImgLa src={url} dimensions={dimensions}/>
				<HeroImgT src={Tablet.url} dimensions={Tablet.dimensions}/>
				<HeroImgM src={Mobile.url} dimensions={Mobile.dimensions}/>
			</HeroImgContainer>
		</Container>
	)
}

export default ProjectDetailsPhoto

ProjectDetailsPhoto.propTypes = {
	input: PropTypes.object.isRequired,
	direction: PropTypes.oneOf(['left', 'right'])
}
