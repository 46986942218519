import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { theme } from '../styles'

const StyledHeader = styled.nav`
  z-index: 99;
  height: 5rem;
  max-width: ${theme.maxWidth};
  margin: auto;
  /* background: cadetblue; */
  @media (max-width: ${theme.breakpoints.s}) {
    align-items: flex-start;
    height: 8rem;
    margin-top: 0.5rem;
  }
  @media (max-width: calc(1560px)){
    margin-left: 3rem;
    margin-right: 3rem;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
	}
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
  a {
    font-family: AvenirBlack;
    color: ${props => props.theme.colors.greyDark};
    margin: 0.5rem 0rem;
    @media (min-width: 601px) {
      margin: 0.5rem 3rem 0.5rem 0rem;
    }
  }
`

const Links = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${theme.breakpoints.s}) {
    margin-top: auto;
  }
  @media (max-width: ${theme.breakpoints.s}) {
    flex-direction: column;
    align-items: flex-end;
    a {
      margin-right: 0rem;
    }
  }
  a {
    :nth-of-type(2) {
      margin-right: 0rem;
      margin-left: 0rem;
    }
  }
`

class Header extends Component {
  render() {
    const { invert } = this.props
    return (
      <StyledHeader invert={invert}>
        <Link to="/" aria-label="Back to Home">
          <img src={'/logos/logo.svg'} alt='Dóróthea Ármann logo'/>
        </Link>
        <Links>
          <Link activeStyle={{ color: theme.colors.yellow }} to="/verkefni" aria-label="Verkefnin">
            VERKEFNI
          </Link>
          <Link activeStyle={{ color: theme.colors.yellow }} to="/about" aria-label="Um mig">
            UM MIG
          </Link>
        </Links>
      </StyledHeader>
    )
  }
}

export default Header

Header.propTypes = {
  invert: PropTypes.bool,
}

Header.defaultProps = {
  invert: false,
}
