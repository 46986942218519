import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const StyledEducation = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	flex: 1;
	width: 100%;
	h4 {
		margin-block-end: 0.5rem;
	}
	h5 {
	}
	margin: 2rem 0rem;
`

const StudyInfo = styled.div`
	flex: 1;
`

const UniversityTimeInfo = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex: 1;
	text-align: right;
`

const Item = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	h5 {
		margin-left: 1rem;
		margin-block-end: 0;
		font-family: AvenirBook;
		font-weight: 400;
		text-align: left;
	}
	img {
		height: 12px;
		width: auto;
	}
	@media (max-width: 600px) {
		h5 {
			margin-left: 0.35rem;
		}
	}
	@media (max-width: 400px) {
		h5 {
			margin-left: 0rem;
		}
		img {
			display: none;
		}
	}

`

const Education = ({ input }) => {
	const { primary: { start_date, end_date, field_of_study, university_name } } = input 
	return (
		<StyledEducation>
			<StudyInfo>
				<h4>{field_of_study.text}</h4>
			</StudyInfo>
			<UniversityTimeInfo>
				<Item>
					<img src={'/logos/degree.svg'} alt='University degree logo'/>
					<h5>{university_name.text}</h5>
				</Item>
				<Item>
					<img src={'/logos/calendar.svg'} alt='Calendar logo'/>
					<h5>{start_date} - {end_date}</h5>
				</Item>
			</UniversityTimeInfo>
		</StyledEducation>
	)
}

export default Education

Education.propTypes = {
  input: PropTypes.object.isRequired,
}
