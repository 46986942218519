const theme = {
  colors: {
    bg: '#F7F4ED',
    black: '#000',
    yellow: '#F2AF29',
    white: '#FFFFFF',
    greyLight: '#B4B4B4',
    grey: '#444444',
    greyDark: '#303643'
  },
  maxWidth: '1440px',
  maxWidthText: '720px',
  breakpoints: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px',
  },
}

export default theme
