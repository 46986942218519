import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled.div`
	margin: 6rem 0rem;
  background-color: ${props => props.theme.colors.grey};
  @media (max-width: ${ props => props.theme.maxWidth}){
		margin-right: -3rem;
		margin-left: -3rem;
  }
  padding: 3rem 4rem;
  h4, h5, p {
    color: ${props => props.theme.colors.white};
  }
`

const QuoteContainer = styled.div`
  img {
    max-height: 36px;
    width: auto;
    margin-bottom: 2rem;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      height: 32px;
    }
  }
`

const QuoteOwnerContainer = styled.div`
  h4 {
    margin-block-end: 0.25rem;
  }
`

const Quote = ({ input }) => {
  return (
    <Container>
      <QuoteContainer>
        <img src='/logos/quote.svg' alt='Block quote logo'/>
        <div dangerouslySetInnerHTML={{ __html: input.primary.quote_text.html }} />
      </QuoteContainer>
      <QuoteOwnerContainer>
        <div dangerouslySetInnerHTML={{ __html: input.primary.quote_owner.html }} />
      </QuoteOwnerContainer>
    </Container>
  )
}

export default Quote

Quote.propTypes = {
  input: PropTypes.object.isRequired,
}
