import React from 'react'
import styled from '@emotion/styled'

const CTAContainer = styled.div`
  padding: 1rem 2rem;
  width: 100%;
  background-color: #485461;
  background-image: linear-gradient(315deg, #28313b 25%, #485461 74%);

  .inner {
    display: flex;
    flex-direction: row;
    margin: 2rem 1rem;
    h1 {
      flex: 1;
    }
    div {
      flex: 2;
    }
  }

  @media (max-width: 1024px) {
    .inner {
      flex-direction: column;
    }
  }
  
  h1, a, p {
    color: white;
  }

  a {
    font-weight: 600;
  }
`

const MePlusYou = () => {
  return (
		<CTAContainer>
			<div className='inner'>
				<h1>
					Ég + þú = ?
				</h1>
				<div>
					<p>
						Ert þú með skemmtilegt verkefni í huga en vantar aðstoð með það, eða ertu með frábæra hugmynd en vantar hjálp við að taka hana lengra?
					</p>
					<p>
						Hvort sem það er verkefna- eða viðburðastjórnun sem þig vantar aðstoð við þá er ég alltaf til í kaffibolla og ræða málin og skoða hvort við pössum ekki saman. 
					</p>
					<p>
						Ekki hika við að hafa samband. Ég hlakka til að heyra frá þér!
					</p>
					<p>
						<a href="mailto:dorothea@dorothea.is">dorothea@dorothea.is</a> {'//'} <a href="tel:6965246">696-5246</a><br />
					</p>
				</div>
			</div>
		</CTAContainer>
  )
}

export default MePlusYou
