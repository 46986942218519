/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Global, css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import '@reach/skip-nav/styles.css'
import styled from '@emotion/styled'

import Footer from './Footer'
import SEO from './SEO'
import SkipNavLink from './SkipNavLink'
import { theme, reset } from '../styles'

import 'typeface-lora'
import 'typeface-source-sans-pro'
import Header from './Header'

const globalStyle = css`
  @font-face {
    font-family: "AvenirMedium";
    src: url("/fonts/Avenir/Avenir-Medium.woff") format("woff");
  }
  @font-face {
    font-family: "AvenirBook";
    src: url("/fonts/Avenir/Avenir-Book.woff") format("woff");
  }
  @font-face {
    font-family: "AvenirBlack";
    src: url("/fonts/Avenir/Avenir-Black.woff") format("woff");
  }
  @font-face {
    font-family: "AvenirRoman";
    src: url("/fonts/Avenir/Avenir-Roman.woff") format("woff");
  }
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.grey};
    font-family: AvenirBlack;
    letter-spacing: 0.04rem;
  }
  h1, h2 {
    font-family: AvenirBlack;
    letter-spacing: 0.08rem;
  }
  h3, h4, h5, h6 {
    font-family: AvenirMedium
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.grey};
    background-color: ${theme.colors.bg};
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.yellow};
  }
  ::-webkit-scrollbar {
    width: 10px;
    background: ${theme.colors.bg};
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.yellow};
    border-radius: 50px;
  }
  p {
    color: ${theme.colors.grey};
    font-family: AvenirBook;
    font-weight: 400;
    font-style: normal;
  }
  a {
    color: ${theme.colors.grey};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-family: AvenirBook;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.02rem;
    &:hover,
    &:focus {
      /* text-decoration: underline; */
    }
  }
  @media (max-width: ${theme.breakpoints.m}) {
    html {
      font-size: 16px !important;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    h1 {
      font-size: 2.369rem !important;
    }
    h2 {
      font-size: 1.777rem !important;
    }
    h3 {
      font-size: 1.333rem !important;
    }
    h4 {
      font-size: 1rem !important;
    }
    h5 {
      font-size: 0.75rem !important;
    }
    h6 {
      font-size: 0.563rem !important;
    }
  }
`

const Content = styled.div`
  max-width: 1440px;
  min-height: calc(100vh - 8rem);
  @media (max-width: ${theme.breakpoints.s}) {
    min-height: ${props => props.shouldRotate ? 'calc(100vh - 8.5rem)' : 'calc(100vh - 11.5rem)'}
  }
  @media (min-width: 601px) and (max-width: ${theme.breakpoints.l}) {
    min-height: ${props => props.shouldRotate ? 'calc(100vh - 5rem)' : 'calc(100vh - 8rem)'}
  }
  margin: 0 auto;
  position: relative;
  flex-grow: 1;
	@media (max-width: 1560px){
    margin-left: 3rem;
    margin-right: 3rem;
  }
	@media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
	}
`

const PureLayout = ({ children, data, customSEO }) => (
  <ThemeProvider theme={theme}>
    <>
      <Global styles={globalStyle} />
      <SkipNavLink />
      {!customSEO && <SEO />}
      {children}
    </>
  </ThemeProvider>
)

class Layout extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            prismicHomepage {
              data {
                email {
                  text
                }
                linkedin {
                  target
                  url
                }
                phone {
                  text
                }
              }
            }
          }
        `}
        render={data => {
          const { prismicHomepage: { data: socialData }} = data;
          return (
            <PureLayout {...this.props} data={data}>
              <Header />
                <Content shouldRotate={this.props.shouldRotate}>
                  {this.props.children}
                </Content>
              <Footer data={socialData} shouldRotate={this.props.shouldRotate}/>
            </PureLayout>
          )
        }}
      />
    )
  }
}

export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}

PureLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  data: PropTypes.object.isRequired,
  customSEO: PropTypes.bool,
}

PureLayout.defaultProps = {
  customSEO: false,
}
