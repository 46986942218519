import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled.div`
	display: flex;
	flex-direction: row;
	margin: 3rem 0rem;
	flex-wrap: wrap-reverse;
	align-items: center;
`

const TextContent = styled.div`
	flex: 1;
	margin-right: 5rem;
	@media (max-width: ${props => props.theme.breakpoints.m}) {
		margin-right: 0rem;
		flex-grow: 1;
		width: 100%;
  }
	a {
		font-weight: 600;
	}
`

const ProjectDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 4rem 2rem;
	@media (max-width: ${props => props.theme.breakpoints.m}) {
		flex-direction: row;
		justify-content: space-between;
		margin: 2rem 0rem;
		flex-grow: 1;
		width: 100%;
  }
	h4 {
		font-family: AvenirMedium;
		margin-block-end: 0.25rem;
	}
	p, a {
		font-size: 0.75rem !important;
		margin-block-end: 0.25rem;
	}
	a {
		text-decoration: underline;
	}
`

const ProjectScope = styled.div`
	p {
		&::before {
      content: "+";
      display: inline-block;
      position: relative;
      margin-right: 0.5rem;
      color: ${props => props.theme.colors.yellow};
      z-index: -1;
    }
	}
`

const ProjectDetail = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0.75rem 0rem;
`

const ProjectDetailsText = ({ input }) => {
	const { primary: { external_link_to_project, project_info_block, project_scope, year } } = input 
	return (
		<Container>
			<TextContent dangerouslySetInnerHTML={{ __html: project_info_block.html }} />
			<ProjectDetailsContainer>
				<ProjectDetail>
					<h5>Verkþættir</h5>
					<ProjectScope dangerouslySetInnerHTML={{ __html: project_scope.html }} />
				</ProjectDetail>
				<ProjectDetail>
					<h5>Ár</h5>
					<p>{year.text}</p>
				</ProjectDetail>
				{external_link_to_project && 
					<ProjectDetail>
						<h5>Hlekkur</h5>
						<a href={external_link_to_project.url} target='_blank' rel='noreferrer noopener'>
							Sjá hér
						</a>
					</ProjectDetail>
				}
			</ProjectDetailsContainer>
		</Container>
	)
}

export default ProjectDetailsText

ProjectDetailsText.propTypes = {
  input: PropTypes.object.isRequired,
}
