module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Dóróthea Ármann', // Navigation and Site Title
  titleAlt: 'Dóróthea Ármann', // Title for JSONLD
  description: 'Sjálfstætt starfandi verkefna- og viðburðastjóri með augun opin fyrir nýjum og spennandi ævintýrum.',
  headline: 'Dóróthea Ármann | Verkefna- og Viðburðarstjóri', // Headline for schema.org JSONLD
  url: 'https://dorothea.is', // Domain of your site. No trailing slash!
  siteLanguage: 'is', // Language Tag on <html> element
  logo: '/assets/ogimage.png', // Used for SEO
  ogLanguage: 'is_IS', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Dorothea', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Bonsai', // Author for schemaORGJSONLD
  themeColor: '#3D63AE',
  backgroundColor: '#F2AF29',

  twitter: '@dorothea', // Twitter Username
  facebook: 'dorothea', // Facebook Site Name
  // googleAnalyticsID: 'UA-47519312-7',

  // skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
