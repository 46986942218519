import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const StyledFooter = styled.footer`
  max-width: ${props => props.theme.maxWidth};
  margin: auto;
  height: 3rem;
  color: ${props => props.theme.colors.grey};
  @media (max-width: ${ props => props.theme.maxWidth}){
    margin-left: 3rem;
    margin-right: 3rem;
  }
	@media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
	}
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 999;
`

const StyledSocialLinkContainer = styled.div`
  :nth-of-type(3) {
    margin-right: 0rem;
  }
  a {
    color: ${props => props.theme.colors.greyDark};
    z-index: 99;
    &::after {
      content: "";
      display: inline-block;
      position: relative;
      margin: 0rem 0.5rem;
      top: 0.35rem;
      width: 0.08rem;
      height: 1.25rem;
      transform: rotate(25deg);
      background: ${props => props.theme.colors.yellow};
      z-index: -1;
    }
  }
  a, p {
		font-size: 0.75rem !important;
		margin-block-end: 0rem;
  }
  :last-child {
    a {
      &::after {
        background: transparent;
        width: 0rem;
        margin: 0rem 0rem;
      }
    }
  }
`


class Footer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mobile: false
    }
  }

  isMobileSafari = () => {
    if (typeof document !== 'undefined') {
      return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
    }
    return false
  }
  
  componentDidMount () {
    if (this.isMobileSafari()) {
      this.setState({mobile: true})
    }
  }

  render() {
    const { data, shouldRotate = false } = this.props
    const { email, linkedin, phone } = data 
    const { mobile } = this.state
    return (
      <StyledFooter css={shouldRotate && css`
        @media (max-width: 1200px) {
          overflow: visible;
          position: absolute;
          right: 2rem;
          left: 0rem;
          bottom: ${mobile ? '8rem' : '3rem'};
          transform: rotate(90deg);
          transform-origin: bottom right;
          flex-wrap: nowrap;
          white-space: nowrap;
        }
      `}>
        <StyledSocialLinkContainer>
          <a href={`mailto:${email.text}`}>
            {email.text}
          </a>
          </StyledSocialLinkContainer>
        <StyledSocialLinkContainer>
          <a href={`tel:${phone.text}`}>
            {phone.text}
          </a>
        </StyledSocialLinkContainer>
        <StyledSocialLinkContainer>
          <a href={linkedin.url} target='_blank' rel='noreferrer noopener'>
            LinkedIn
          </a>
        </StyledSocialLinkContainer>
      </StyledFooter>
    )
  }
}

export default Footer

Footer.propTypes = {
  children: PropTypes.node,
}
